module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NQ9FVN8NCX"],"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 639px)","sm":"(min-width: 640px) and (max-width: 767px)","md":"(min-width: 768px) and (max-width: 1023px)","lg":"(min-width: 1024px) and (max-width: 1279px)","xl":"(min-width: 1280px) and (max-width: 1535px)","xxl":"(min-width: 1536px)"}},
    }]
